
/*
 * QUILL - SAMPLE OVERRIDES
 */
 @import '~ngx-toastr/toastr.css';
 @import "~bootstrap/dist/css/bootstrap.css";




// vcircle :start
/* You can add global styles to this file, and also import other style files */

.mediadisabled{
    background-color: gray;
}

button.fa {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.usercontrols {
      font-size: unset !important;
  }
  .usercontrols button {
    background: transparent;
    border: 0px !important;
    outline: 0px !important;
    transition: none;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
  }
  button.fa-comment {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px;
    margin-right: 10px;
}
button.fa-stack {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px;
    margin-right: 10px;
}
.fa-phone-alt {
    border: 0px;
    outline: 0px;
    background: red !important;
    color: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 14px !important;	
}
button.fa-microphone {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}
button.fa-video {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
    margin-right: 10px;
}
button.fa-desktop {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}
button.fa-user {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}
button.fa-cog {
    background: #fff !important;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 17px !important;
    margin-right: 10px;
}
button.fa-hand-paper {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}

// .btn {
//     border-radius: 5px !important;
//     font-weight: 400 !important;
//     text-transform: uppercase !important;
// }

// .btn-primary {
//     background-color: var(--primary-darker) !important;
//     border-color: var(--primary-darker) !important;

//     &:focus, &.focus {
//         box-shadow: 0 0 0 0.2rem var(--primary) !important;
//     }

//     &:hover {
//         background-color: var(--primary-darkest) !important;
//         border-color: var(--primary-darkest) !important;
//     }
// }

.videosettingsdiv button {
    border: 0px;
    background: rgba(238, 239, 243, 0.8);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    font-size: 14px;
    transition: none;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.videosettingsdiv button[disabled]{
    color: buttontext;
}


.videosettingsdiv button:last-of-type {
    margin-right: 0;
}

button.focus {
    background: #def8f2 !important;
    outline: 0px !important;
}
.settingsdiv {
    // float: left;
    // width: 100%;
    padding : 15px;
    text-align: center;
    // display: inline-block;
}
//change span settings to h3 
.settingsdiv h3 {
    font-size: 15px;
    text-decoration: none !important;
    text-align: center;
}
.settingsdiv-rows {
	// float: left;
	width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.settingsdiv-rows-left {
    /* float: left; */
    width: 210px;
    margin-right: .5rem;
    text-align: left;
}

.settingsdiv-rows-right {
    /* float: left; */
    width: 180%;
}

.frame {
    border: 1px solid #e1e1e2;
    border-radius: 5px;
}

.settingsdiv-rows-right select {
    width: 100%;
}
.videodiv {
    text-align: center !important;
    float: left;
    width: 100%;
	// background: #fcfcff;
}
.button-wrap {
    float: left;
     width: 100%;
     padding-top: 15px;
     padding-bottom: 15px;
 }
 
 .videosettingsdiv button:not(.mediadisabled) {
    //background: #def8f2 !important;
    background-color: #fff;
    outline: 0px !important;
 }
 .settingsdiv-rows-left p {
     margin-bottom: 10px;
 }
 .screensharediv h1 {
    font-size: 44px;
 }
 button.stop-btn {
    font-size: 18px;
    background: #009776;
    color: #fff;
    border: 0px;
    padding: 10px 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    top: -20px;
    position: relative;
 }

.settingswidth {
    // max-width: 485px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.participantnamediv{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em;
    text-align: center;
    margin-top: 3px;
    font-size: .9rem;
}


.hostsettings .settingsdiv-rows-left p{
    margin-bottom: 0px;
}

.hostsettings .settingsdiv-rows{
    margin-bottom: 5px;
}

button[disabled]{
    background-color: gray;
}

.bg-grey {
    background-color: #949a9f !important;
}

.inline-block{
    display: inline-block;
}



.dropdown-menu{
    transition: unset;
}

.no-more-video-component-animations{
    width: 100% !important;
    animation: none !important;
}

.volume-meter{
    // height: 100%;
    height: 6px;
    max-width: min(480px, 100%);
    background-color:#85c5b8;
    margin: auto;
    // max-width: 100%;
    transition: all 0.1s;
    width: 0px;
    border-radius: 10px;
    // display: inline-block;
}

video.localparticipant {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
  }

  #endMeetingModal .modal-body{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .mobile #endMeetingModal .modal-body{
    flex-direction: column;
  }

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #8b8b8b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #8b8b8b;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px #8b8b8b;
  }
  
  ::-webkit-scrollbar-corner {
    background: #8b8b8b;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .participantbignamediv{
    // display: inline-block;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    }

    .participantbignamespan{
        font-size: xxx-large;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-wrap: balance
    }

    .mobile .participantbignamespan{
        font-size: xx-large;
    }

    .meparticipantcontainerdiv .participantbignamespan, 
    .meparticipantcontainerdiv .usernamespan, 
    .meparticipantcontainerdiv .namespan{
        color: #0d9fa3;
    }

    .participantbignamediv,
    .usernamespan,
    .namespan{
        font-weight: bold;
    }

@media (max-width: 576px) {
    .settingsdiv-rows {
        flex-direction: column;
        margin-bottom: 10px !important;
    }

    .settingsdiv-rows-left, .settingsdiv-rows-right {
        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    .settingsdiv-rows-right select {
        max-width: 100%;
    }    
}
// vcircle end




quill-editor {
  display: block;
}

.ql-editor:focus {
  border: 1px solid #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.ng-invalid {
  border: 1px dashed red;
}

/* Set dropdown font-families */

[quill-editor-toolbar] .ql-font span[data-label="Aref Ruqaa"]::before {
  font-family: "Aref Ruqaa";
}

[quill-editor-toolbar] .ql-font span[data-label="Mirza"]::before {
  font-family: "Mirza";
}

[quill-editor-toolbar] .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}

/* Set content font-families */

.ql-font-mirza {
  font-family: "Mirza";
}
.pagination
{
 flex-wrap: wrap;
 display: flex;
 justify-content: center;
 color: cornflowerblue;
}
.page-item.active .page-link
{
  background-color: cornflowerblue;
  border-color: cornflowerblue;
}
.ql-font-aref {
  font-family: "Aref Ruqaa";
}

.ql-font-roboto {
  font-family: "Roboto";
}

/*
 * ANGULAR MATERIAL
 */

// Import library functions for theme creation.
@import '~@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();

// Define the default theme (same as the example above).
$primary: mat-palette($mat-blue, A700);
$accent:  mat-palette($mat-blue-grey, A200, A100, A400);
$warn:    mat-palette($mat-red);

$theme:   mat-light-theme($primary, $accent);

// Include the default theme styles.
@include angular-material-theme($theme);

/*
 * COMPONENTS
 */

// make major example component children full width
app-root > div > *,
app-root > div > * > form,
app-root > div > * > ng-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

app-root > div > * {
  // any root element in an example component, but H3s
  > *:not(h3) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  // make major example component children full width
  h4,
  > div,
  > .ql-container,
  quill-editor,
  .mat-form-field {
    flex: 1;
  }

  h3 {
    background: #e0e2e3;
    font-family: sans-serif;
    padding: 2rem;
  }

  h4 {
    text-align: center;
  }

  > button {
    flex: 1 1 32px;
  }

  // only style the example component pre, not quill's
  > pre,
  form > pre {
    background-color: #fcfcfc;
    border: 1px solid #b2b2b2;
    color: #363030;
    padding: 1.5rem;
    white-space: normal;
  }
}

/*
 * Material Quill & MatFormField
 * - demo overrides
 */

.mat-form-field {

  // Since the default quill styles already provide padding
  &-appearance-fill .mat-form-field {
    &-flex {
      padding: 0 !important;
    }

    &-infix {
      border-top: 0;
      padding: 0;
    }
  }

  .ql-container {
    // set quill caret to match current Angular Material theme
    caret-color: mat-color($primary, text);

    // Remove redundant quill snow-theme border
    &.ql-snow {
      border: none;
    }

    // Remove all default quill focus indicators
    .ql-editor {
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  // make sure any quill tooltips, bubbles, or popups aren't occluded
  .ql-tooltip {
    z-index: 1;
  }
}

/* TOASTR BUGFIX */
#toast-container > div {
  opacity: 1;
}
.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}
.toast-success {
  background-color: #51A351 !important;
}
.toast-error {
  background-color: #BD362F !important;
}
.toast-info {
  background-color: #2F96B4 !important;
}
.toast-warning {
  background-color: #F89406 !important;
}

.spanner {
  position: absolute;
  top: 50%;
  left: 0;
  /* background: #2a2a2a55; */
  width: 100%;
  display: block;
  text-align: center;
  /* height: 300px; */
  color: #FFF;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  visibility: hidden;
  z-index: 1000;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.show {
  visibility: visible;
}

.spanner, .overlay {
  opacity: 10;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show, .overlay.show {
  opacity: 5
}
.higlight{
  color: red;
}

.section-title h2 {
  font-size: 20px !important;
}

section.lead > p > span {
  font-size: 18px !important;
  color: #000;
}
/* loading spinner */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/* make spiner centralized on all browser */
.spin-wrap {
  float: left;
  width: 100%;
  background: rgba(0,0,0, .7);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
}

.spin-wrap-content {
  width: 100%;
  /* max-width: 180px; */
  margin: 0 auto;
  text-align: center;
}

.loading {
  float: left;
  width: 100%;
}

.loading p {
  text-align: center;
  color: #fff !important;
  margin-top: 90px;
}

.spinner-container {
  float: left;
  width: 100%;
}

body, html {
  height: 100% !important;
  width: 100% !important;
}
/* end spinner centralized on all broswer */
/* end loading spinner */

.cornblue
  {
    background-color:cornflowerblue !important ;
    color: white !important;
  }

  .candidate-video {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
    clip-path: circle(40%);
  }

  button:disabled {
    background-color: grey !important;
  }
  
  .body-loading{
    background: url(/assets/img/loading.gif);
    background-repeat: no-repeat no-repeat;
    background-position: center center;
  }

  b,strong{
    /*to override bootstrap*/
    font-weight: bold !important;
  }
  
  /*
 * QUILL - SAMPLE OVERRIDES
 */

quill-editor {
  display: block;
}

.ql-editor:focus {
  border: 1px solid #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.ng-invalid {
  border: 1px dashed red;
}

/* Set dropdown font-families */

[quill-editor-toolbar] .ql-font span[data-label="Aref Ruqaa"]::before {
  font-family: "Aref Ruqaa";
}

[quill-editor-toolbar] .ql-font span[data-label="Mirza"]::before {
  font-family: "Mirza";
}

[quill-editor-toolbar] .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}

/* Set content font-families */

.ql-font-mirza {
  font-family: "Mirza";
}

.ql-font-aref {
  font-family: "Aref Ruqaa";
}

.ql-font-roboto {
  font-family: "Roboto";
}

app-option{
  display: inline-block;
}

// .grecaptcha-badge{
//   visibility: hidden
// }

// #recaptcha-footer{
//   width: 100%;
//   display: block;
//   position: fixed;
//   bottom: 0px;
//   background-color: white;
// }

// #recaptcha-footer > span{
//   width: 100%;
//   display: inline-block;
//   text-align: center;
// }

// #recaptcha-footer *{
//   display: unset;
//   margin-left: unset;
//   margin-right: unset;
// }

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

#recaptcha-footer{
  display: none;
}
